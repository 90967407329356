import React from "react";
import { withBreakpoints } from 'gatsby-plugin-breakpoints';
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// Images
import carouselImage1 from "assets/img/carousel/DomApotheke.jpg";
import carouselImage2 from "assets/img/carousel/DomApothekeBeratung.jpg";
import carouselImage3 from "assets/img/carousel/Kommisionierer.jpg";
import carouselImage4 from "assets/img/carousel/FalkenapothekeBeratung.jpg";
import akkreditierung from "assets/img/akkreditierung.jpg";
import "assets/scss/material-kit-react.scss?v=1.4.0";
import 'typeface-roboto';
import 'typeface-roboto-slab';
// @material-ui/icons

// SEO
import { GatsbySeo } from 'gatsby-plugin-next-seo';

// core components
import Footer from "components/Footer/FooterBuerscheFalkenApotheke.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import Carousel from "react-slick";

import landingPageStyle from "assets/jss/material-kit-react/views/landingPage.jsx";

// Sections for this page
import TeamSection from "./Sections/TeamSection.jsx";
import WorkSection from "./Sections/WorkSection.jsx";
import HeaderSection from "./Sections/HeaderSection.jsx";

const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true
};

class SchreinersApotheken extends React.Component {

    state = {openmessage: ''}
    anchorElShop = null;
    anchorElRecipes = null;
    constructor(props) {
        super(props);
        this.state = {
            classicModal: false,
            openLeft: false,
            openTop: false,
            openShop: false,
            openRecipes: false,
            openRight: false
        };

    }
    handleClickOpen(modal) {
        var x = [];
        x[modal] = true;
        this.setState(x);
    }
    handleClose(modal) {
        var x = [];
        x[modal] = false;
        this.setState(x);
    }
    handleClosePopover(state) {
        this.setState({
            [state]: false
        });
    }
    handleClickButton(state) {
        this.setState({
            [state]: true
        });
    }

    checkTime() {
        var now = new Date();
        // DEBUG
       // now.setHours(now.getHours() - 0);
       // console.log(now);
        var message = "jetzt geöffnet";
        var dayOfWeek = now.getDay(); // 0 = Sunday, 1 = Monday, ... 6 = Saturday
        var hour = now.getHours(); // 0 = 12am, 1 = 1am, ... 18 = 6pm
        var minutes = now.getMinutes();

        console.log(dayOfWeek);
        console.log(hour);
        console.log(minutes);

        // check if it's a weekday between 9am and 6pm
        if (dayOfWeek > 0 && dayOfWeek < 5 && hour > 7 && hour < 19) {
            if (hour > 16) {
                message = 'schließt um 18:30 Uhr';
            }
        } else if (dayOfWeek > 0 && dayOfWeek < 6 && hour > 7 && hour < 20) {
            message = 'jetzt geöffnet';
        } else if (dayOfWeek === 6 && hour > 9 && hour < 13) {
            message = 'jetzt geöffnet';
            if (hour > 11) {
                message = 'schließt um 13:00 Uhr';
            }
        } else if (dayOfWeek === 5 && hour > 19) {
            message = 'öffnet Samstags um 9:00';
        } else if (dayOfWeek === 6 && hour < 9 && hour > 5) {
            message = 'öffnet um 9:00';
        } else if (dayOfWeek === 6 && hour > 11) {
            message = 'schließt um 13:00 Uhr';
        } else {
            message = 'jetzt geschlossen';
            if (hour > 6) {
                message = 'öffnet um 8:00';
            }
        }

        console.log(message);
        this.setState({
            openmessage: message
        });
    };

    componentDidMount() {
        this.checkTime();
        this.timer = setInterval(() => {  this.checkTime() }, 60000);
    }

    componentWillUnmount() {
        clearInterval(this.timer)
    }

    render() {
        const {classes,breakpoints, ...rest} = this.props;

        return (
            <>
            <GatsbySeo
                title='Buersche Falken Apotheke'
                description='Ein starkes Team in Buer. Die Buersche Falken Apotheke und die Dom-Apotheke sind für Sie da.'
                canonical='https://www.buersche-falken-apotheke.de'
                openGraph={{
                    url: 'https://www.buersche-falken-apotheke.de',
                    title: 'Buersche Falken Apotheke',
                    description: 'Ein starkes Team in Buer. Die Dom-Apotheke und die Buersche Falken Apotheke sind für Sie da.',
                    images: [
                        {
                            url: 'https://www.schreiners-apotheken.de/falkenapotheke-card.jpg',
                            width: 900,
                            height: 800,
                            alt: 'Buersche Falken-Apotheke',
                        },
                        {
                            url: 'https://www.schreiners-apotheken.de/falkenapotheke-card.jpg',
                            width: 800,
                            height: 600,
                            alt: 'Buersche Falken-Apotheke',
                        },
                    ],
                    site_name: 'Buersche Falken Apotheke',
                }}
            />
            <div>
                <HeaderSection />
                <div className={classNames(classes.main, classes.mainRaised)}>
                    <div className={classes.container}>
                        <TeamSection/>
                        {breakpoints.sm
                            ? null
                            : <GridContainer>
                                <GridItem xs={12} sm={12} md={12}>
                                    <Card>
                                        <Carousel {...settings}>
                                            <div>
                                                <img
                                                    src={carouselImage1}
                                                    alt="Dom-Apotheke Außenansicht mit Brunnen"
                                                    className="slick-image"
                                                />
                                                <div className="slick-caption">
                                                    <h2>
                                                        Mitten in Buer
                                                    </h2>
                                                </div>
                                            </div>
                                            <div>
                                                <img
                                                    src={carouselImage2}
                                                    alt="Beratungsgespräch in der Apotheke"
                                                    className="slick-image"
                                                />
                                                <div className="slick-caption">
                                                    <h2>
                                                        Kompentente Beratung
                                                    </h2>
                                                </div>
                                            </div>
                                            <div>
                                                <img
                                                    src={carouselImage3}
                                                    alt="Kommisionierer in Bewegung"
                                                    className="slick-image"
                                                />
                                                <div className="slick-caption">
                                                    <h2>
                                                        Auf dem Stand der Technik
                                                    </h2>
                                                </div>
                                            </div>
                                        </Carousel>
                                    </Card>
                                </GridItem>
                            </GridContainer>
                        }
                        <WorkSection/>
                    </div>
                </div>
                <Footer/>
            </div>
            </>
        );
    }
}

export default withBreakpoints(withStyles(landingPageStyle)(SchreinersApotheken));

